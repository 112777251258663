import { NestedKeys } from "advanced-types";
import dayjs from "dayjs";
import React, { FC, Fragment, useMemo } from "react";
import { Translation } from "../../../assets/i18n/locals/en";
import {
  availabilityType,
  detailsConservationType,
  detailsFurnitureType,
  detailsHabitabilityType,
  detailsQualityType,
  heatingDetailsSystemType,
  heatingDetailsType,
  landKind,
  propertyOptionsLand,
} from "../../../forms/realeEstate/selections";
import { useI18n } from "../../../hooks/useI18n";
import { RealEstateCommercial } from "../../../models/RealeEstateCommercial";
import { RealEstate } from "../../../models/RealEstate";
import {
  formatCurrency,
  isCommercial,
  isLand,
  revereKeysWithValue,
} from "../../../Utils/fuctions";
import "./SpecificInformation.scss";
import { RealEstateLand } from "../../../models/RealEstateLand";

interface ISpecificInformation {
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand;
}

type IData = {
  label: NestedKeys<Translation, ".">;
  value: string | number | undefined;
};

const SpecificInformation: FC<ISpecificInformation> = ({ realEstate }) => {
  const {
    location: {
      totalFloors,
      floor,
      missingArchitecturalBarriers,
      seaLakeDistance,
      elevator,
      position,
    },
    price: { requestedPrice, condominiumFees, negotiable },
    locals,
    rooms,

    bathrooms,
    constructionYear,
    totalSquareMeters,
    totalSquareMetersWalkable,
    certifications: { energyCertification },
    heatingDetails: { type: fuel, system, radiators, expenses },
    additionalDetails: {
      quality,
      habitability,
      conservationStatus,
      windows,
      intercom,
      reception,
      furniture,
      exposition,
    },
  } = realEstate;
  const isRealEstateCommercial = isCommercial(realEstate);
  const isRealEstateLand = isLand(realEstate);
  const getFloor = (floor: string) => {
    if (!isNaN(Number(floor))) return floor;
    return t(`floorLevel.${floor}` as unknown as NestedKeys<Translation, ".">);
  };
  const { t, getTranslateFromObj, language } = useI18n();

  const dataSpecificInformation: IData[] = [
    {
      label: "realEstate.forms.compactRealEstate.specificInformation.surface",
      value: `${
        totalSquareMeters
          ? `${totalSquareMeters.toLocaleString("it-IT")} mq`
          : "-"
      } `,
    },
    {
      label: "realEstate.forms.compactRealEstate.specificInformation.floor",
      value: floor ? getFloor(floor) : "-",
    },
    {
      label:
        "realEstate.forms.compactRealEstate.specificInformation.totalFloors",
      value: `${
        totalFloors
          ? `${totalFloors} ${t(
              "realEstate.forms.compactRealEstate.specificInformation.floors"
            )}`
          : "-"
      } `,
    },
    {
      label: "realEstate.forms.compactRealEstate.specificInformation.position",
      value: position,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.specificInformation.distanceSeaLake",
      value: `${seaLakeDistance ? `${seaLakeDistance} m` : "-"} `,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.specificInformation.constructionYear",
      value: constructionYear ? dayjs(constructionYear).format("YYYY") : "-",
    },
    {
      label: "realEstate.forms.compactRealEstate.specificInformation.elevator",
      value: elevator
        ? t(
            `realEstate.forms.compactRealEstate.specificInformation.${
              elevator === "yes" ? "yes" : "no"
            }`
          )
        : undefined,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.specificInformation.architecturalBarriers",
      value:
        missingArchitecturalBarriers !== undefined
          ? t(
              `realEstate.forms.compactRealEstate.specificInformation.${
                !missingArchitecturalBarriers ? "yes" : "no"
              }`
            )
          : undefined,
    },
    isRealEstateCommercial
      ? {
          label:
            "realEstate.forms.compactRealEstate.specificInformation.locals",
          value: [
            locals
              ? `${locals} ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.locals"
                )}`
              : ` 0 ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.locals"
                )}`,
            realEstate.showcases
              ? `${realEstate.showcases} ${t(
                  `realEstate.forms.compactRealEstate.specificInformation.showcases`
                )}`
              : `0${t(
                  "realEstate.forms.compactRealEstate.specificInformation.showcases"
                )}`,
            bathrooms
              ? `${bathrooms} ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.bathrooms"
                )}`
              : `0 ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.bathrooms"
                )}`,
          ].join(" - "),
        }
      : {
          label:
            "realEstate.forms.compactRealEstate.specificInformation.locals",
          value: [
            locals
              ? `${locals} ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.locals"
                )}`
              : ` 0 ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.locals"
                )}`,
            rooms
              ? `${rooms} ${t(
                  `realEstate.forms.compactRealEstate.specificInformation.rooms`
                )}`
              : `0 ${t(
                  `realEstate.forms.compactRealEstate.specificInformation.rooms`
                )}`,
            bathrooms
              ? `${bathrooms} ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.bathrooms"
                )}`
              : `0 ${t(
                  "realEstate.forms.compactRealEstate.specificInformation.bathrooms"
                )}`,
          ].join(" - "),
        },
  ];
  if (isRealEstateLand) {
    const labelsToRemove = [
      "realEstate.forms.compactRealEstate.specificInformation.floor",
      "realEstate.forms.compactRealEstate.specificInformation.totalFloors",
      "realEstate.forms.compactRealEstate.specificInformation.constructionYear",
      "realEstate.forms.compactRealEstate.specificInformation.locals",
      "realEstate.forms.compactRealEstate.specificInformation.elevator",
    ];

    labelsToRemove.forEach((labelToRemove) => {
      const index = dataSpecificInformation.findIndex(
        (item) => item.label === labelToRemove
      );
      if (index !== -1) {
        dataSpecificInformation.splice(index, 1);
      }
    });
  }

  const dataPrice: IData[] = [
    {
      label: "realEstate.forms.compactRealEstate.price.requestedPrice",
      value: `€ ${formatCurrency("EUR", requestedPrice, false)}`,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.price.monthlyCondominiumExpenses",
      value: condominiumFees
        ? `€ ${formatCurrency("EUR", condominiumFees, false)}`
        : "-",
    },
    {
      label: "realEstate.forms.compactRealEstate.price.PriceOption.title",
      value: `${t(
        `realEstate.forms.compactRealEstate.price.PriceOption.${
          negotiable ? "negotiable" : "notNegotiable"
        }`
      )}`,
    },
  ];
  if (isRealEstateLand) {
    const index = dataPrice.findIndex(
      (item) =>
        item.label ===
        "realEstate.forms.compactRealEstate.price.monthlyCondominiumExpenses"
    );

    if (index !== -1) {
      dataPrice.splice(index, 1);
    }
  }
  const dataFeatures: IData[] = [
    {
      label: "realEstate.forms.compactRealEstate.features.walkableMeters",
      value: `${
        totalSquareMetersWalkable ? `${totalSquareMetersWalkable} mq` : "-"
      } `,
    },
    {
      label: "realEstate.forms.compactRealEstate.features.fuel",
      value: getTranslateFromObj(heatingDetailsType, fuel),
    },
    {
      label: "realEstate.forms.compactRealEstate.features.system",
      value: getTranslateFromObj(heatingDetailsSystemType, system),
    },
    {
      label: "realEstate.forms.compactRealEstate.features.radiators",
      value: radiators,
    },
    {
      label: "realEstate.forms.compactRealEstate.features.monthlyExpenses",
      value: expenses
        ? `€ ${formatCurrency("EUR", expenses, false)}`
        : undefined,
    },
  ];

  if (isRealEstateLand) {
    dataFeatures.length = 0;
    dataFeatures.push(
      {
        label: "realEstate.forms.compactRealEstate.features.landKind",
        value:
          getTranslateFromObj(landKind, realEstate.landKind) === ""
            ? "-"
            : getTranslateFromObj(landKind, realEstate.landKind),
      },
      {
        label: "realEstate.forms.compactRealEstate.features.propertyType",
        value:
          getTranslateFromObj(propertyOptionsLand, realEstate.propertyType) ===
          ""
            ? "-"
            : getTranslateFromObj(propertyOptionsLand, realEstate.propertyType),
      },
      {
        label: "realEstate.forms.compactRealEstate.features.buildabilityIndex",
        value: realEstate.buildabilityIndex
          ? realEstate.buildabilityIndex
          : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.features.buildableSquareMeters",
        value: realEstate.buildableSquareMeters
          ? realEstate.buildableSquareMeters
          : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.features.agriculturalSquareMeters",
        value: realEstate.agriculturalSquareMeters
          ? realEstate.agriculturalSquareMeters
          : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.features.constructionPresent",
        value: realEstate.constructionPresent ? "Sì" : "No",
      },
      {
        label: "realEstate.forms.compactRealEstate.features.approvedProject",
        value: realEstate.approvedProject ? "Sì" : "No",
      },
      {
        label: "realEstate.forms.compactRealEstate.features.possibleProduction",
        value: realEstate.possibleProduction
          ? realEstate.possibleProduction
          : "-",
      }
    );
  }

  const dataAdditionalFeatures: IData[] = [
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.quality",
      value: getTranslateFromObj(detailsQualityType, quality),
    },
    {
      label:
        "realEstate.forms.compactRealEstate.additionalFeatures.habitability",
      value: getTranslateFromObj(detailsHabitabilityType, habitability),
    },
    {
      label:
        "realEstate.forms.compactRealEstate.additionalFeatures.conservationStatus",
      value: t(
        revereKeysWithValue(detailsConservationType)[conservationStatus]
      ),
    },
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.windows",
      value: windows,
    },
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.intercom",
      value: getTranslateFromObj(availabilityType, intercom),
    },
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.reception",
      value: getTranslateFromObj(availabilityType, reception),
    },
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.furnished",
      value: getTranslateFromObj(detailsFurnitureType, furniture),
    },
    {
      label: "realEstate.forms.compactRealEstate.additionalFeatures.exposition",
      value: exposition,
    },
  ];

  if (isRealEstateLand) {
    dataAdditionalFeatures.length = 0;
    dataAdditionalFeatures.push(
      {
        label:
          "realEstate.forms.compactRealEstate.additionalFeatures.exposition",
        value: exposition ? exposition : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.additionalFeatures.easementOfPassage",
        value: realEstate.additionalDetails.easementOfPassage
          ? realEstate.additionalDetails.easementOfPassage
          : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.additionalFeatures.preEmptionRights",
        value: realEstate.additionalDetails.preEmptionRights
          ? realEstate.additionalDetails.preEmptionRights
          : "-",
      },
      {
        label:
          "realEstate.forms.compactRealEstate.additionalFeatures.plantations",
        value: realEstate.additionalDetails.plantations
          ? realEstate.additionalDetails.plantations
          : "-",
      }
    );
  }

  const dataEnergyEfficiency: IData[] = [
    {
      label: "realEstate.forms.compactRealEstate.energyEfficiency.class",
      value: energyCertification?.class,
    },
    {
      label: "realEstate.forms.compactRealEstate.energyEfficiency.EpGinr",
      value: energyCertification?.performanceIndex
        ? `${energyCertification.performanceIndex} kWh/m²`
        : undefined,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.energyEfficiency.performanceUnit",
      value: energyCertification?.performanceUnit,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.energyEfficiency.winterConsumption",
      value: energyCertification?.energyPerformance.winter,
    },
    {
      label:
        "realEstate.forms.compactRealEstate.energyEfficiency.summerConsumption",
      value: energyCertification?.energyPerformance.summer,
    },
  ];

  if (isRealEstateLand) dataEnergyEfficiency.length = 0;

  const renderInformations = (
    data: {
      label: NestedKeys<Translation, ".">;
      value: string | number | undefined;
    }[]
  ) => {
    return (
      <>
        <table className="specific-information-table">
          <tbody>
            {data.map(({ label, value }, index) => {
              return value !== undefined && value !== null ? (
                <tr className="tr" key={index}>
                  <th className="th">{t(label)}</th>
                  <td className="td">
                    {value === "notProvided" ? "-" : value}
                  </td>
                </tr>
              ) : (
                <Fragment key={index} />
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const renderSpecificInformationMemo = useMemo(
    () => (
      <>
        <p className="specific-information-title mt-5 mb-2">
          {t("realEstate.forms.compactRealEstate.price.title")}
        </p>
        {renderInformations(dataPrice)}
        <p className="specific-information-title mt-5 mb-2">
          {t("realEstate.forms.compactRealEstate.specificInformation.title")}
        </p>
        {renderInformations(dataSpecificInformation)}
        <p className="specific-information-title mt-5 mb-2">
          {t("realEstate.forms.compactRealEstate.features.title")}
        </p>
        {renderInformations(dataFeatures)}
        <p className="specific-information-title mt-5 mb-2">
          {t("realEstate.forms.compactRealEstate.additionalFeatures.title")}
        </p>
        {renderInformations(dataAdditionalFeatures)}
        {!isRealEstateLand && (
          <p className="specific-information-title mt-5 mb-2">
            {t("realEstate.forms.compactRealEstate.energyEfficiency.title")}
          </p>
        )}

        {renderInformations(dataEnergyEfficiency)}
      </>
    ),
    // eslint-disable-next-line
    [
      dataPrice,
      dataSpecificInformation,
      dataFeatures,
      dataAdditionalFeatures,
      dataEnergyEfficiency,
      language,
    ]
  );
  return <Fragment>{renderSpecificInformationMemo}</Fragment>;
};

export default SpecificInformation;
