import { NestedKeys } from "advanced-types";
import { Translation } from "../../../../assets/i18n/locals/en";
import {
  kitchenTypes,
  heatingDetailsType,
  detailsConservationType,
  eletricalSystemType,
  tvOptions,
  boxTypes,
  gardenTypes,
  floorNumbers,
  SelectOptions,
  parkingTypes,
  landKind,
} from "../../../../forms/realeEstate/selections";
import { ConditionElement } from "../../../../models/demandCustomer";

export type ISection = "internal" | "relevance" | "external" | "other";
export type IFIlters<
  T extends NestedKeys<Translation, "."> = NestedKeys<Translation, ".">
> = Record<
  ConditionElement["name"],
  {
    section: ISection;
    label: T;
    selectedItems?:
      | { label: string; value: string }
      | { label: string; value: string }[];
    selectType:
      | SelectOptions<any>
      | boolean
      | number
      | Record<string, number | undefined>;
  }
>;
export type ILandFilters = Pick<
  IFIlters,
  | "waterPipes"
  | "facingStreet"
  | "phoneLines"
  | "divisibleLot"
  | "methane"
  | "approvedProject"
  | "constructionPresent"
  | "landKind"
  | "bareOwnership"
  | "atAuction"
  | "lakeView"
  | "seaView"
>;
export const landFilters: ILandFilters = {
  waterPipes: {
    label: "realEstate.forms.description.cards.waterPipes",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  facingStreet: {
    label: "realEstate.forms.description.cards.facingStreet",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  phoneLines: {
    label: "realEstate.forms.description.cards.phoneLines",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  divisibleLot: {
    label: "realEstate.forms.description.cards.divisibleLot",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  methane: {
    label: "realEstate.forms.description.cards.methane",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  constructionPresent: {
    label: "realEstate.forms.description.cards.constructionPresent",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  approvedProject: {
    label: "realEstate.forms.description.cards.approvedProject",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  bareOwnership: {
    label:
      "realEstate.forms.residential.generalForm.titles.details.labels.checkbox.bareOwnership.option",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  landKind: {
    label: "realEstate.forms.description.cards.landKind",
    selectedItems: [{ label: "*", value: "*" }],
    section: "internal",
    selectType: { "*": "*", ...landKind },
  },
  atAuction: {
    label: "selectOptions.otherInfoLabel.AT_AUCTION",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  lakeView: {
    label: "selectOptions.otherInfoLabel.LAKE_VIEW",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  seaView: {
    label: "selectOptions.otherInfoLabel.SEA_VIEW",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
};

export type IFiltersResidentialCommercial = Omit<
  IFIlters,
  | "waterPipes"
  | "facingStreet"
  | "phoneLines"
  | "divisibleLot"
  | "methane"
  | "approvedProject"
  | "constructionPresent"
  | "landKind"
>;

export const filters: IFiltersResidentialCommercial = {
  kitchen: {
    label: "selectOptions.additionalRooms.KITCHEN",
    section: "internal",
    selectedItems: [{ label: "*", value: "*" }],
    selectType: { "*": "*", ...kitchenTypes },
  },
  heatingType: {
    label: "realEstate.forms.description.heating.title",
    selectedItems: [{ label: "*", value: "*" }],
    section: "internal",
    selectType: { "*": "*", ...heatingDetailsType },
  },
  conservationStatus: {
    label:
      "realEstate.forms.description.additionalFeatures.labels.conservationsStatus",
    section: "internal",
    selectedItems: [{ label: "*", value: "*" }],
    selectType: { "*": "*", ...detailsConservationType },
  },
  airConditioning: {
    label: "selectOptions.otherInfoLabel.AIR_CONDITIONING",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  chimney: {
    label: "selectOptions.otherInfoLabel.CHIMNEY",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  safetyBox: {
    label: "selectOptions.otherInfoLabel.SAFETY_BOX",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  shower: {
    label: "selectOptions.otherInfoLabel.SHOWER",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  electricalSystem: {
    label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM",
    selectedItems: [{ label: "*", value: "*" }],
    section: "internal",
    selectType: { "*": "*", ...eletricalSystemType },
  },
  phoneSystem: {
    label: "selectOptions.otherInfoLabel.PHONE_SYSTEM",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  aluminiumFixtures: {
    label: "selectOptions.otherInfoLabel.ALUMINIUM_FIXTURES",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  woodFixtures: {
    label: "selectOptions.otherInfoLabel.WOOD_FIXTURES",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  parquet: {
    label: "selectOptions.otherInfoLabel.PARQUET",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  shutters: {
    label: "selectOptions.otherInfoLabel.SHUTTERS",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  alarmPredisposed: {
    label: "selectOptions.otherInfoLabel.ALARM_PREDISPOSED",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  closet: {
    label: "selectOptions.otherInfoLabel.CLOSET",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  suspendedSanitary: {
    label: "selectOptions.otherInfoLabel.SUSPENDED_SANITARY",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },
  satTv: {
    label: "selectOptions.otherInfoLabel.SAT_TV",
    selectedItems: [{ label: "*", value: "*" }],
    section: "internal",
    selectType: { "*": "*", ...tvOptions },
  },
  jacuzzi: {
    label: "selectOptions.otherInfoLabel.JACUZZI",
    selectedItems: { label: "*", value: "*" },
    section: "internal",
    selectType: false,
  },

  box: {
    label: "realEstate.forms.description.cards.garage",
    selectedItems: [{ label: "*", value: "*" }],
    section: "relevance",
    selectType: { "*": "*", ...boxTypes },
  },
  cellar: {
    label: "realEstate.forms.description.cards.cellar",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  depot: {
    label: "realEstate.forms.description.cards.depot",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  warehouse: {
    label: "realEstate.forms.description.cards.warehouse",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  loft: {
    label: "realEstate.forms.description.cards.loft",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  attic: {
    label: "realEstate.forms.description.cards.attic",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  stable: {
    label: "realEstate.forms.description.cards.stable",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  pool: {
    label: "selectOptions.otherInfoLabel.POOL",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
    section: "relevance",
  },
  petsAllowed: {
    label:
      "realEstate.forms.residential.generalForm.titles.details.labels.checkbox.petsAllowed.option",
    section: "other",
    selectedItems: { label: "*", value: "*" },
    selectType: false,
  },
  bareOwnership: {
    label:
      "realEstate.forms.residential.generalForm.titles.details.labels.checkbox.bareOwnership.option",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  tvAntenna: {
    label: "selectOptions.otherInfoLabel.TV_ANTENNA",
    selectedItems: [{ label: "*", value: "*" }],
    section: "other",
    selectType: { "*": "*", ...tvOptions },
  },
  atAuction: {
    label: "selectOptions.otherInfoLabel.AT_AUCTION",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  porch: {
    label: "selectOptions.otherInfoLabel.PORCH",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  lakeView: {
    label: "selectOptions.otherInfoLabel.LAKE_VIEW",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  seaView: {
    label: "selectOptions.otherInfoLabel.SEA_VIEW",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  elevator: {
    label: "realEstate.forms.location.info.labels.elevator",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  floor: {
    label: "realEstate.forms.location.floor",
    selectedItems: [{ label: "*", value: "*" }],
    section: "other",
    selectType: { "*": "*", ...floorNumbers },
  },
  independentEntrance: {
    label: "selectOptions.otherInfoLabel.INDEPENDENT_ENTRANCE",
    selectedItems: { label: "*", value: "*" },
    section: "other",
    selectType: false,
  },
  balconies: {
    label: "realEstate.forms.description.cards.balconies",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  garden: {
    label: "realEstate.forms.description.cards.garden",
    selectedItems: [{ label: "*", value: "*" }],
    section: "external",
    selectType: { "*": "*", ...gardenTypes },
  },
  coveredParkingSpaces: {
    label: "realEstate.forms.description.cards.coveredParkingSpaces",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: 0,
  },
  openParkingSpaces: {
    label: "realEstate.forms.description.cards.openParkingSpaces",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: 0,
  },
  boatParkingSpaces: {
    label: "realEstate.forms.description.cards.boatParkingSpaces",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: 0,
  },
  camperParkingSpaces: {
    label: "realEstate.forms.description.cards.camperParkingSpaces",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: 0,
  },
  bikeParkingSpaces: {
    label: "realEstate.forms.description.cards.bikeParkingSpaces",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: 0,
  },
  parkingSpaces: {
    label: "customers.demandsCustomers.conditionsModal.parkingSpaces",
    selectedItems: [{ label: "*", value: "*" }],
    section: "external",
    selectType: { "*": "*", ...parkingTypes },
  },
  shed: {
    label: "realEstate.forms.description.cards.shed",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  terrace: {
    label: "realEstate.forms.description.cards.terrace",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
  terrain: {
    label: "realEstate.forms.description.cards.terrain",
    selectedItems: { label: "*", value: "*" },
    section: "external",
    selectType: false,
  },
};

export const areasFilters: {
  label: NestedKeys<Translation, ".">;
  value: ISection;
}[] = [
  {
    label: "customers.demandsCustomers.conditionsModal.areas.external",
    value: "external",
  },
  {
    label: "customers.demandsCustomers.conditionsModal.areas.internal",
    value: "internal",
  },
  {
    label: "customers.demandsCustomers.conditionsModal.areas.relevance",
    value: "relevance",
  },
  {
    label: "customers.demandsCustomers.conditionsModal.areas.other",
    value: "other",
  },
];
