import { ICustomForm } from "../../../models/CustomForm";
import {
  LAND_PROPERTY_TYPE,
  RealEstateLand,
} from "../../../models/RealEstateLand";
import {
  AvailabilityStatus,
  CONTRACT_REGISTRATION,
  CONTRACT_TYPE,
  CURRENT_STATE,
  Mediation,
  MEDIATION,
  PROPERTY_TYPE,
  RentDetails,
  RENT_CONTRACT,
  WARRANTY,
} from "../../../models/RealEstate";
import { fillSelection } from "../../../Utils/fuctions";
import {
  contractOptions,
  currentStateOptions,
  mediationOptions,
  propertyOptionsLand,
  rentDetailsContractRegistration,
  rentDetailsContractType,
  rentDetailsRequestedWarranty,
  stateOptions,
} from "./../selections";

export interface IGeneralForm {
  acquisitionDate?: string;
  state?: LAND_PROPERTY_TYPE;
  mediation?: Mediation;
  owners: string[];
  rentDetails: RentDetails;
  availability: AvailabilityStatus;
  virtualTourUrl: string;
  videoUrl: string;
  bareOwnership: boolean;
  contractType: CONTRACT_TYPE;
  currentState: CURRENT_STATE;
  notes: string;
  propertyType: PROPERTY_TYPE;
}

export const initialValueGeneralFormLand = {
  acquisitionDate: "",
  state: "",
  mediation: {
    type: "",
    from: "",
    to: "",
  },
  virtualTourUrl: "",
  videoUrl: "",
  owners: [] as string[],
  availability: {
    availableNow: false,
    date: "",
    notes: "",
  },
  rentDetails: {
    contractType: RENT_CONTRACT.NOT_PROVIDED, // tipo di contratto
    requestedWarranty: WARRANTY.NOT_PROVIDED, // garanzia richiesta
    contractRegistration: CONTRACT_REGISTRATION.NOT_PROVIDED, // registrazione contratto
    dryTax: false, // cedolare secca
  },
  bareOwnership: false,
  contractType: CONTRACT_TYPE.NOT_PROVIDED,
  currentState: CURRENT_STATE.NOT_PROVIDED,
  notes: "",
  propertyType: PROPERTY_TYPE.NOT_PROVIDED,
};

export const generalFormLand: ICustomForm<IGeneralForm> = {
  title: {
    edit: "realEstate.forms.residential.generalForm.title.edit",
    show: "realEstate.forms.residential.generalForm.title.edit",
  },
  typePage: "view",
  description: {
    edit: "realEstate.forms.residential.generalForm.description.edit",
    show: "realEstate.forms.residential.generalForm.description.show",
  },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "price",
  forms: [
    {
      title:
        "realEstate.forms.residential.generalForm.titles.informationRealState.title",
      fields: [
        {
          label:
            "realEstate.forms.residential.generalForm.titles.informationRealState.labels.acquisitionDate",
          type: "datepicker",
          isRequired: true,
          key: "acquisitionDate",
        },

        {
          label:
            "realEstate.forms.residential.generalForm.titles.informationRealState.labels.state",
          type: "select",
          isRequired: true,
          key: "state",
          options: fillSelection(stateOptions),
        },
        {
          column: 5,
          label:
            "realEstate.forms.residential.generalForm.titles.informationRealState.labels.mediationType",
          type: "select",
          isRequired: true,
          key: "mediation/type",
          options: fillSelection(mediationOptions),
        },
        {
          column: 3,
          label:
            "realEstate.forms.residential.generalForm.titles.informationRealState.labels.mediationFrom",
          type: "datepicker",
          showIfKey: "mediation",
          showIf: ({ type }: RealEstateLand["mediation"]) =>
            type === MEDIATION.EXCLUSIVE || type === MEDIATION.NOT_EXCLUSIVE,
          isRequired: false,
          key: "mediation/from",
        },
        {
          column: 3,
          label:
            "realEstate.forms.residential.generalForm.titles.informationRealState.labels.mediationTo",
          type: "datepicker",
          showIfKey: "mediation",
          showIf: ({ type }: RealEstateLand["mediation"]) =>
            type === MEDIATION.EXCLUSIVE || type === MEDIATION.NOT_EXCLUSIVE,
          isRequired: false,
          key: "mediation/to",
        },
      ],
    },
    {
      title: "realEstate.forms.residential.generalForm.titles.property.title",
      fields: [
        {
          maxColumn: 12,
          label:
            "realEstate.forms.residential.generalForm.titles.property.labels.owner",
          type: "owner",
          key: "owners",
        },
      ],
    },
    {
      title: "realEstate.forms.residential.generalForm.titles.details.title",
      fields: [
        {
          maxColumn: 12,
          label:
            "realEstate.forms.residential.generalForm.titles.details.labels.contractType",
          type: "select",
          isRequired: true,
          key: "contractType",
          options: fillSelection(contractOptions),
        },
        {
          label:
            "realEstate.forms.residential.generalForm.titles.details.labels.rentDetails.contractType",
          type: "select",
          showIfKey: "contractType",
          showIf: (item: CONTRACT_TYPE) => item === CONTRACT_TYPE.RENT,
          key: "rentDetails/contractType",
          options: fillSelection(rentDetailsContractType),
        },
        {
          label:
            "realEstate.forms.residential.generalForm.titles.details.labels.rentDetails.requestedWarranty",
          type: "select",
          showIfKey: "contractType",
          showIf: (item: CONTRACT_TYPE) => item === CONTRACT_TYPE.RENT,
          key: "rentDetails/requestedWarranty",
          options: fillSelection(rentDetailsRequestedWarranty),
        },
        {
          label:
            "realEstate.forms.residential.generalForm.titles.details.labels.rentDetails.contractRegistration",
          type: "select",
          showIfKey: "contractType",
          showIf: (item: CONTRACT_TYPE) => item === CONTRACT_TYPE.RENT,
          key: "rentDetails/contractRegistration",
          options: fillSelection(rentDetailsContractRegistration),
        },
        {
          column: 12,
          label: "",
          type: "checkbox",
          showIfKey: "contractType",
          showIf: (item: CONTRACT_TYPE) => item === CONTRACT_TYPE.RENT,
          key: "rentDetails/dryTax",
          option: {
            label:
              "realEstate.forms.residential.generalForm.titles.details.labels.checkbox.dryTax",
            value: false,
          },
        },
        {
          label:
            "realEstate.forms.residential.generalForm.titles.details.labels.rentDetails.propertyType",
          type: "select",
          isRequired: true,
          key: "propertyType",
          options: fillSelection(propertyOptionsLand),
        },
        {
          label: "",
          type: "checkbox",
          key: "bareOwnership",
          option: {
            label:
              "realEstate.forms.residential.generalForm.titles.details.labels.checkbox.bareOwnership.option",
            value: false,
          },
        },
      ],
    },
    {
      title:
        "realEstate.forms.residential.generalForm.titles.virtualTour.title",
      fields: [
        {
          label:
            "realEstate.forms.residential.generalForm.titles.virtualTour.labels.virtualTourUrl",
          type: "text",
          key: "virtualTourUrl",
        },
        {
          label:
            "realEstate.forms.residential.generalForm.titles.virtualTour.labels.media",
          type: "text",
          key: "videoUrl",
        },
      ],
    },
    {
      title:
        "realEstate.forms.residential.generalForm.titles.availability.title",
      fields: [
        {
          maxColumn: 12,
          label:
            "realEstate.forms.residential.generalForm.titles.availability.labels.currentState",
          type: "select",
          key: "currentState",
          options: fillSelection(currentStateOptions),
        },
        {
          label: "",
          type: "checkbox",
          key: "availability/availableNow",
          option: {
            label:
              "realEstate.forms.residential.generalForm.titles.availability.labels.availableNow.option",
            value: true,
          },
          tooltip: {
            label:
              "realEstate.forms.residential.generalForm.titles.availability.labels.availableNow.tooltip",
          },
        },
        {
          label: "",
          type: "datepicker",
          showIfKey: "availability/availableNow",
          showIf: (item: boolean) => !item,
          key: "availability/date",
        },
        {
          column: 12,
          label:
            "realEstate.forms.residential.generalForm.titles.availability.labels.details",
          showIfKey: "availability/availableNow",
          showIf: (item: boolean) => !item,
          type: "text-area",
          key: "availability/notes",
        },
      ],
    },
    {
      title:
        "realEstate.forms.residential.generalForm.titles.availability.labels.notes",
      fields: [
        {
          column: 12,
          label:
            "realEstate.forms.residential.generalForm.titles.availability.labels.notes",
          type: "text-area",
          key: "notes",
        },
      ],
    },
  ],
};
