import React, { FC, useMemo } from "react";
import {
  ILocationForm,
  locationForm,
} from "../../../../forms/realeEstate/location";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form, FormValidationObject } from "../../../../hooks/useForm";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import "./Location.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import {
  ILocationFormCommercial,
  locationFormCommercial,
} from "../../../../forms/realeEstate/Commercial/locationCommercial";
import { useI18n } from "../../../../hooks/useI18n";
import { addValidationQuery, getQueryUrl } from "../../../../Utils/fuctions";
import { useToastAlert } from "../../../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../../../Utils/costants";
import { cloneDeep } from "lodash";
import { ROOM_PERTINENCE_TYPE } from "../../../../models/RealEstate";
import { RoutesRouter } from "../../../../models/Routes";
import { locationFormLand } from "../../../../forms/realeEstate/Land/locationLand";
import { ILocationFormLand } from "../../../../forms/realeEstate/Land/locationLand";
import { AxiosError } from "axios";

interface ILocation {}

const Location: FC<ILocation> = () => {
  const dispatch = useDispatch();
  const { putRealEstate } = useEndpoints();
  const absoluteNavigate = useNavigate();
  const toastAlert = useToastAlert();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const {
    isLoading,
    initialValue,
    generateForm,
    navigate,
    id,
    isCommercial,
    isLand,
    setRealStateRedux,
    goBackPath,
  } = useRealEstateForm<ILocationForm>("location", "location");
  const { t } = useI18n();

  const { required } = {
    required: t("errorMessagesForm.required"),
  };
  const { isSubmit, putRealEstate: putRealEstateSelector } = useSelector(
    ({ realEstate }: RootState) => realEstate
  );
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);

  const handleSubmit = async (
    form: Partial<Form<ILocationForm>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => {
    let submitForm = cloneDeep(form);
    submitForm.longitude === "" && (submitForm.longitude = null);
    submitForm.latitude === "" && (submitForm.latitude = null);
    if ("missingArchitecturalBarriers" in submitForm) {
      const value = submitForm.missingArchitecturalBarriers;
      submitForm["missingArchitecturalBarriers"] =
        (value as unknown as ROOM_PERTINENCE_TYPE) ===
        ROOM_PERTINENCE_TYPE.ABSENT;
    }

    try {
      if (!Boolean(isValidation)) {
        if (!isSame) {
          await putRealEstate(id!, "location", submitForm);
          setRealStateRedux(submitForm, "location");
        }
        isSubmit.submit && navigate(addValidationQuery(next, false));
        !isSubmit.submit &&
          absoluteNavigate(
            `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params)} `
          );
        isBack && navigate(goBackPath());
        return;
      }
      if (isSame && isBack) {
        navigate(goBackPath());
        return;
      }
      if (checkErrors) {
        await putRealEstate(id!, "location", submitForm);
        setRealStateRedux(submitForm, "location");
        navigate(
          isBack
            ? goBackPath()
            : addValidationQuery(next, Boolean(isValidation))
        );
        return;
      }
    } catch (error) {
      toastAlert({
        message: t("realEstate.forms.residential.generalForm.errorToast.title"),
        subMessage: t(
          `realEstate.forms.residential.generalForm.errorToast.${
            error instanceof AxiosError &&
            error.response?.data.error === "Minimum requirements not met"
              ? "requirementsNotMet"
              : "description"
          }`
        ),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };
  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const validatorResidential: FormValidationObject<ILocationForm> = {
    country: { required },
    state: { required },
    city: { required },
    street: { required },
    latitude: { custom: (value) => (value === 0 ? required : "") },
    longitude: { custom: (value) => (value === 0 ? required : "") },
    floor: { required },
    elevator: { required },
    zipCode: { required },
    municipalityId: {},
  };

  const validatorCommercial: FormValidationObject<ILocationFormCommercial> = {
    country: { required },
    state: { required },
    city: { required },
    latitude: { custom: (value) => (value === 0 ? required : "") },
    longitude: { custom: (value) => (value === 0 ? required : "") },
    street: { required },
    floor: { required },
    zipCode: { required },
    municipalityId: {},
  };

  const validatorLand: FormValidationObject<ILocationFormLand> = {
    country: { required },
    state: { required },
    city: { required },
    latitude: { custom: (value) => (value === 0 ? required : "") },
    longitude: { custom: (value) => (value === 0 ? required : "") },
    street: { required },
    zipCode: { required },
    municipalityId: {},
  };

  const validatorsForm = () =>
    isCommercial
      ? validatorCommercial
      : isLand
      ? validatorLand
      : validatorResidential;

  const form = useMemo(
    () => generateForm(isValidation ? validatorsForm() : {}),
    // eslint-disable-next-line
    [initialValue, putRealEstateSelector, isCommercial]
  );

  const renderCustomForm = () => {
    return (
      // @ts-ignore
      <CustomForm<ILocationForm>
        checkErrorsOnInit={Boolean(isValidation)}
        submitOnError={true}
        className="custom-form-container-stepper"
        doSubmit={{ isSubmit, onSubmitted: handleOnSubmitted }}
        submit={handleSubmit}
        form={form}
        props={
          isCommercial
            ? locationFormCommercial
            : isLand
            ? locationFormLand
            : locationForm
        }
      />
    );
  };

  return !isLoading && putRealEstateSelector && initialValue ? (
    renderCustomForm()
  ) : (
    <Loader />
  );
};

export default Location;
