import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Form, FormValidationObject } from "../../hooks/useForm";
import { IDemandCustomer, Zone } from "../../models/demandCustomer";
import CustomForm from "../../shared/components/CustomForm/CustomForm";
import "./DemandCustomer.scss";
import {
  createDemandCustomerForm,
  createDemandCustomerFormCommercial,
  createDemandCustomerFormLand,
  initialValueDemandCustomerForm,
} from "../../forms/customers/demandCustomer";
import { ICustomForm } from "../../models/CustomForm";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { filterKeyForForm } from "../../Utils/fuctions";
import Loader from "../../shared/components/Loader/Loader";
import { Demand } from "../../models/RespDemands";
import { useI18n } from "../../hooks/useI18n";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../Utils/costants";
import { ROOM_PERTINENCE_TYPE } from "../../models/RealEstate";
import { cloneDeep } from "lodash";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
interface IDemandCustomerForm {}

export type DemandTabs = "residential" | "commercial" | "land";

const DemandCustomer: FC<IDemandCustomerForm> = () => {
  const { idDemand } = useParams();
  const [selectedTab, setSelectedTab] = useState<DemandTabs>("residential");
  const [initialValueForm, setInitialValueForm] = useState();
  const [initialValueFormCommercial, setInitialValueFormCommercial] =
    useState();
  const [initialValueFormLand, setInitialValueFormLand] = useState();
  const { putDemandCustomer, getDemandCustomer } = useEndpoints();
  const [isNew, setIsNew] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useI18n();

  const { required, enterAtLeastOneZone } = {
    required: t("errorMessagesForm.required"),
    enterAtLeastOneZone: t("errorMessagesForm.enterAtLeastOneZone"),
  };

  const transformData = (data: any) => ({
    ...data,
    missingArchitecturalBarriers: data.missingArchitecturalBarriers
      ? "absent"
      : "present",
  });

  const initializeForms = (
    isNew: boolean,
    data: any,
    activeTab: DemandTabs
  ) => {
    const initialValues = filterKeyForForm(
      data,
      initialValueDemandCustomerForm
    );

    const forms = {
      residential: () => setInitialValueForm(initialValues),
      commercial: () => setInitialValueFormCommercial(initialValues),
      land: () => setInitialValueFormLand(initialValues),
    };

    if (isNew) {
      Object.keys(forms).forEach((key) => forms[key as DemandTabs]());
    } else {
      forms[activeTab]?.();
    }
  };

  const fetchDemandCustomer = async () => {
    try {
      const newReq = searchParams.get("isNew");
      const { data } = await getDemandCustomer(idDemand!);

      const initialTab = newReq ? "residential" : data.type;
      setSelectedTab(initialTab);

      setIsNew(!!newReq);

      const transformedData = transformData(data);

      initializeForms(!!newReq, transformedData, initialTab);
    } catch (error) {
      console.error("Error fetching demand customer:", error);
    }
  };

  useEffect(() => {
    fetchDemandCustomer();
    // eslint-disable-next-line
  }, []);

  const generateForm = useCallback(
    (validators: FormValidationObject<Demand> | undefined = undefined) => {
      // @ts-ignore
      return { initialValue: initialValueForm, validators };
    },
    [initialValueForm]
  );
  const generateFormCommercial = useCallback(
    (validators: FormValidationObject<Demand> | undefined = undefined) => {
      // @ts-ignore
      return { initialValue: initialValueFormCommercial, validators };
    },
    [initialValueFormCommercial]
  );
  const tempValueRef = useRef<{
    priceFrom: number;
    totalSquareMetersFrom: number;
    localsFrom: number;
    roomsFrom: number;
    bathroomsFrom: number;
    bedsFrom: number;
    seaLakeDistanceFrom: number;
  }>({
    priceFrom: 0,
    totalSquareMetersFrom: 0,
    localsFrom: 0,
    roomsFrom: 0,
    bathroomsFrom: 0,
    bedsFrom: 0,
    seaLakeDistanceFrom: 0,
  });

  const generateFormLand = useCallback(
    (validators: FormValidationObject<Demand> | undefined = undefined) => {
      return { initialValue: initialValueFormLand, validators };
    },
    [initialValueFormLand]
  );
  const toastAlert = useToastAlert();

  const createRangeFieldValidators = (
    fields: string[],
    tempValueRef: React.MutableRefObject<any>,
    t: (key: string) => string
  ) => {
    return fields.reduce((acc, field) => {
      const fromField = `${field}/from`;
      const toField = `${field}/to`;

      acc[fromField] = {
        custom: (value: number) => {
          tempValueRef.current[field + "From"] = value;
          return "";
        },
      };

      acc[toField] = {
        custom: (value: number) => {
          const fromValue = tempValueRef.current[field + "From"];
          if (fromValue > value && value !== 0) {
            return t("errorMessagesForm.invalidRange");
          }
          return "";
        },
      };

      return acc;
    }, {} as Record<string, any>);
  };

  const formFields = [
    "price",
    "totalSquareMeters",
    "locals",
    "rooms",
    "bathrooms",
    "beds",
    "seaLakeDistance",
  ];

  const formLandFields = [
    "price",
    "totalSquareMeters",
    "buildabilityIndex",
    "buildableSquareMeters",
    "agriculturalSquareMeters",
  ];

  const form = useMemo(() => {
    return generateForm({
      contractType: { required },
      status: { required },
      zones: {
        custom: (value: Zone[]) => (value.length ? "" : enterAtLeastOneZone),
      },
      ...createRangeFieldValidators(
        formFields,
        tempValueRef,
        t as (key: string) => string
      ),
    });
  }, []);

  const formCommercial = useMemo(() => {
    return generateFormCommercial({
      contractType: { required },
      status: { required },
      zones: {
        custom: (value: Zone[]) => (value.length ? "" : enterAtLeastOneZone),
      },
      ...createRangeFieldValidators(
        formFields,
        tempValueRef,
        t as (key: string) => string
      ),
    });
  }, []);

  const tempLandValuesRef = useRef<{
    priceFrom: number;
    totalSquareMetersFrom: number;
    buildabilityIndexFrom: number;
    buildableSquareMetersFrom: number;
    agriculturalSquareMetersFrom: number;
  }>({
    priceFrom: 0,
    totalSquareMetersFrom: 0,
    buildabilityIndexFrom: 0,
    buildableSquareMetersFrom: 0,
    agriculturalSquareMetersFrom: 0,
  });

  const formLand = useMemo(() => {
    return generateFormLand({
      contractType: { required },
      status: { required },
      zones: {
        custom: (value: Zone[]) => (value.length ? "" : enterAtLeastOneZone),
      },
      ...createRangeFieldValidators(
        formLandFields,
        tempLandValuesRef,
        t as (key: string) => string
      ),
    });
  }, [form]);

  function cleanFormFieldsRecursive<T extends Record<string, any>>(obj: T): T {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      const cleanedObj = { ...obj } as T;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (key === "from" || key === "to") {
            cleanedObj[key] = (
              obj[key] === "" || obj[key] == null ? 0 : obj[key]
            ) as T[typeof key];
          } else {
            cleanedObj[key] = cleanFormFieldsRecursive(
              obj[key]
            ) as T[typeof key];
          }
        }
      }
      return cleanedObj;
    } else if (Array.isArray(obj)) {
      return obj.map((item) => cleanFormFieldsRecursive(item)) as unknown as T;
    } else {
      return obj;
    }
  }

  const handleSubmit = async (
    submitForm: Partial<Form<IDemandCustomer>>,
    next: ICustomForm["next"]
  ) => {
    const form = cleanFormFieldsRecursive(cloneDeep(submitForm));
    const value = form.missingArchitecturalBarriers;
    /*  form["missingArchitecturalBarriers"] =
      ((value as unknown) as ROOM_PERTINENCE_TYPE) ===
      ROOM_PERTINENCE_TYPE.ABSENT; */

    try {
      await putDemandCustomer(idDemand!, form);
      toastAlert({
        message: t("agency.successToast.title"),
        subMessage: t("agency.successToast.description"),
        type: "success",
        duration: DURATION_TOAST,
      });
      navigate(-1);
    } catch {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };
  const handleSubmitCommercial = async (
    submitForm: Partial<Form<IDemandCustomer>>
  ) => {
    const form = cleanFormFieldsRecursive(cloneDeep(submitForm));
    const value = form.missingArchitecturalBarriers;
    form.type = selectedTab;

    try {
      await putDemandCustomer(idDemand!, form);
      toastAlert({
        message: t("agency.successToast.title"),
        subMessage: t("agency.successToast.description"),
        type: "success",
        duration: DURATION_TOAST,
      });
      navigate(-1);
    } catch {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  const prepareLandDemandPayload = (data: any) => {
    return {
      type: data.type,
      title: data.title,
      status: data.status,
      contractType: data.contractType,
      zones: data.zones.map((zone: any) => ({
        name: zone.name,
        coords: zone.coords,
        center: zone.center,
        area: zone.area,
        perimeter: zone.perimeter,
        bounds: zone.bounds,
      })),
      price: data.price,
      totalSquareMeters: data.totalSquareMeters,
      miscellaneous: data.miscellaneous,
      specificNeeds: data.specificNeeds,
      gotFinancialAdvice: data.gotFinancialAdvice,
      isLoan: data.isLoan,
      loanAmount: data.loanAmount,
      isCash: data.isCash,
      propertyTypes: data.propertyTypes,
      buildabilityIndex: data.buildabilityIndex,
      buildableSquareMeters: data.buildableSquareMeters,
      agriculturalSquareMeters: data.agriculturalSquareMeters,
      conditions: {
        required: data.conditions.required.map((condition: any) => ({
          name: condition.name,
          value: condition.value,
        })),
        optional: data.conditions.optional.map((condition: any) => ({
          name: condition.name,
          value: condition.value,
        })),
      },
    };
  };

  const handleSubmitLand = async (
    submitForm: Partial<Form<IDemandCustomer>>
  ) => {
    const form = cleanFormFieldsRecursive(cloneDeep(submitForm));
    form.type = selectedTab;

    try {
      await putDemandCustomer(idDemand!, prepareLandDemandPayload(form));
      toastAlert({
        message: t("agency.successToast.title"),
        subMessage: t("agency.successToast.description"),
        type: "success",
        duration: DURATION_TOAST,
      });
      navigate(-1);
    } catch {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  const renderCustomFormResidential = () => (
    // @ts-ignore
    <CustomForm
      key="residential-form"
      submit={handleSubmit}
      form={{ ...form, initialValue: initialValueForm }}
      props={createDemandCustomerForm}
      className="custom-height-request"
    />
  );
  const renderCustomFormCommercial = () => (
    // @ts-ignore
    <CustomForm
      key="commercial-form"
      submit={handleSubmitCommercial}
      form={{ ...formCommercial, initialValue: initialValueFormCommercial }}
      props={createDemandCustomerFormCommercial}
      className="custom-height-request"
    />
  );

  const renderCustomFormLand = () => (
    <CustomForm
      key="land-form"
      submit={handleSubmitLand}
      form={{ ...formLand, initialValue: initialValueFormLand }}
      props={createDemandCustomerFormLand}
      className="custom-height-request"
    />
  );

  return (
    <div className="height-test">
      <Tabs variant="soft-rounded" colorScheme="blue" className="pt-2 ps-1">
        <TabList>
          <Tab
            isSelected={selectedTab === "residential"}
            onClick={() => setSelectedTab("residential")}
            _selected={{
              color: "#3b54d4",
              bg: "#eceffd",
              "&:focus": { boxShadow: "none" },
            }}
            sx={{ color: "#3a3878", "&:focus": { boxShadow: "none" } }}
            className={`${
              isNew ? "" : selectedTab === "residential" ? "" : "d-none"
            }`}
          >
            {t("realEstate.addRealEstate.RESIDENTIAL")}
          </Tab>

          <Tab
            isSelected={selectedTab === "commercial"}
            onClick={() => setSelectedTab("commercial")}
            _selected={{
              color: "#3b54d4",
              bg: "#eceffd",
              "&:focus": { boxShadow: "none" },
            }}
            sx={{ color: "#3a3878", "&:focus": { boxShadow: "none" } }}
            className={`${
              isNew ? "" : selectedTab === "commercial" ? "" : "d-none"
            }`}
          >
            {t("realEstate.addRealEstate.COMMERCIAL")}
          </Tab>

          <Tab
            isSelected={selectedTab === "land"}
            onClick={() => setSelectedTab("land")}
            _selected={{
              color: "#3b54d4",
              bg: "#eceffd",
              "&:focus": { boxShadow: "none" },
            }}
            sx={{ color: "#3a3878", "&:focus": { boxShadow: "none" } }}
            className={`${isNew ? "" : selectedTab === "land" ? "" : "d-none"}`}
          >
            {t("realEstate.addRealEstate.LAND")}
          </Tab>
        </TabList>
      </Tabs>
      {selectedTab === "residential" ? (
        <div>
          {initialValueForm ? renderCustomFormResidential() : <Loader />}
        </div>
      ) : selectedTab === "land" ? (
        <div>{initialValueFormLand ? renderCustomFormLand() : <Loader />}</div>
      ) : (
        <div>
          {initialValueFormCommercial ? (
            <>
              <div>{renderCustomFormCommercial()}</div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  );
};

export default DemandCustomer;
